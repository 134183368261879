const _temp0 = require("./directive/disable-withdrawal-list.js");
const _temp1 = require("./directive/scroll-to-element.js");
const _temp2 = require("./directive/sportaza-checked-wb.js");
const _temp3 = require("./directive/sportaza-img/sportaza-img.js");

module.exports = {
  "disable-withdrawal-list.js": _temp0,
  "scroll-to-element.js": _temp1,
  "sportaza-checked-wb.js": _temp2,
  "sportaza-img":   {
    "sportaza-img.js": _temp3
  }
}