const _temp0 = require("./directive/_alf-payment-slider-widget.js");
const _temp1 = require("./directive/_slider-range.js");
const _temp2 = require("./directive/_update-class-on-scroll-to.js");
const _temp3 = require("./directive/abandoned-registration.js");
const _temp4 = require("./directive/add-class-on-event.js");
const _temp5 = require("./directive/add-class-on-login.js");
const _temp6 = require("./directive/air-datepicker.js");
const _temp7 = require("./directive/animate-title-leave.js");
const _temp8 = require("./directive/bind-analytics-click.js");
const _temp9 = require("./directive/bind-analytics.js");
const _temp10 = require("./directive/bind-html-compile.js");
const _temp11 = require("./directive/cashbox-state.js");
const _temp12 = require("./directive/chat-open.js");
const _temp13 = require("./directive/check-last-deposit.js");
const _temp14 = require("./directive/check-pending-withdrawals.js");
const _temp15 = require("./directive/closed-registration-tracker.js");
const _temp16 = require("./directive/confetti.js");
const _temp17 = require("./directive/cookie-settings.js");
const _temp18 = require("./directive/copy-to-clipboard.js");
const _temp19 = require("./directive/datepicker-birthday.js");
const _temp20 = require("./directive/datepicker.js");
const _temp21 = require("./directive/embed-src.js");
const _temp22 = require("./directive/get-categories-to-scope.js");
const _temp23 = require("./directive/get-collection-gamehall-to-scope.js");
const _temp24 = require("./directive/get-collections-to-scope.js");
const _temp25 = require("./directive/get-config-to-scope.js");
const _temp26 = require("./directive/get-country-layout-to-scope.js");
const _temp27 = require("./directive/get-current-time-to-scope.js");
const _temp28 = require("./directive/get-footer-data-to-scope.js");
const _temp29 = require("./directive/get-modal-content-to-scope.js");
const _temp30 = require("./directive/get-popup-content-to-scope.js");
const _temp31 = require("./directive/get-private-categories.js");
const _temp32 = require("./directive/get-promo-links-to-scope.js");
const _temp33 = require("./directive/get-promos-to-scope.js");
const _temp34 = require("./directive/get-providers-to-scope.js");
const _temp35 = require("./directive/get-sport-teams-to-scope.js");
const _temp36 = require("./directive/get-state-params-to-scope.js");
const _temp37 = require("./directive/get-system-info-to-scope.js");
const _temp38 = require("./directive/get-timestamp-to-scope.js");
const _temp39 = require("./directive/get-user-to-scope.js");
const _temp40 = require("./directive/get-vips-to-scope.js");
const _temp41 = require("./directive/go-back.js");
const _temp42 = require("./directive/handle-game-page-shop-tab.js");
const _temp43 = require("./directive/hide-after-ny.js");
const _temp44 = require("./directive/hide-by-country-code.js");
const _temp45 = require("./directive/hide-element-if.js");
const _temp46 = require("./directive/hide-for-sw.js");
const _temp47 = require("./directive/history-go-back.js");
const _temp48 = require("./directive/holiday-link.js");
const _temp49 = require("./directive/horizontal-scroll-element.js");
const _temp50 = require("./directive/href-if-auth.js");
const _temp51 = require("./directive/infinite-scroll.js");
const _temp52 = require("./directive/input-auto-width.js");
const _temp53 = require("./directive/input-filter-creditcard.js");
const _temp54 = require("./directive/install-app-box.js");
const _temp55 = require("./directive/lazy-bg.js");
const _temp56 = require("./directive/license-icon.js");
const _temp57 = require("./directive/linda-scroll-top.js");
const _temp58 = require("./directive/logout.js");
const _temp59 = require("./directive/lottie-animation.js");
const _temp60 = require("./directive/lucky-winner-to-scope.js");
const _temp61 = require("./directive/mask-as.js");
const _temp62 = require("./directive/mask-phone-v2.js");
const _temp63 = require("./directive/mask-phone.js");
const _temp64 = require("./directive/mask-pt-postcode.js");
const _temp65 = require("./directive/mask-zipcode.js");
const _temp66 = require("./directive/native-datepicker.js");
const _temp67 = require("./directive/on-success.js");
const _temp68 = require("./directive/parallax.js");
const _temp69 = require("./directive/password-validate.js");
const _temp70 = require("./directive/password-verify.js");
const _temp71 = require("./directive/play-track-on-click.js");
const _temp72 = require("./directive/popup-close.js");
const _temp73 = require("./directive/popup-open.js");
const _temp74 = require("./directive/promo-button.js");
const _temp75 = require("./directive/regily-avatar-to-reward.js");
const _temp76 = require("./directive/remove-class-on-clickoutside.js");
const _temp77 = require("./directive/remove-class-on-event.js");
const _temp78 = require("./directive/remove-if-pwa.js");
const _temp79 = require("./directive/scroll-element-to-center-on-state-change.js");
const _temp80 = require("./directive/scroll-left-after-repeat.js");
const _temp81 = require("./directive/scroll-to-current-date.js");
const _temp82 = require("./directive/scroll-to-top-on-click.js");
const _temp83 = require("./directive/scroll-to-top.js");
const _temp84 = require("./directive/scroll-when-hold.js");
const _temp85 = require("./directive/select-template-given-expression.js");
const _temp86 = require("./directive/select-template-given-user-status.js");
const _temp87 = require("./directive/seo-text.js");
const _temp88 = require("./directive/set-popup-content-to.js");
const _temp89 = require("./directive/show-by-counrty-code.js");
const _temp90 = require("./directive/show-element-if.js");
const _temp91 = require("./directive/show-element-on-success.js");
const _temp92 = require("./directive/show-game-link-by-location.js");
const _temp93 = require("./directive/spine-player.js");
const _temp94 = require("./directive/swiper-slider.js");
const _temp95 = require("./directive/terms-modal-open.js");
const _temp96 = require("./directive/time-passed.js");
const _temp97 = require("./directive/title-switcher.js");
const _temp98 = require("./directive/toggle-class-at-elemetns.js");
const _temp99 = require("./directive/toggle-class-on-event.js");
const _temp100 = require("./directive/toggle-class-on-scroll.js");
const _temp101 = require("./directive/toggle-group-class-on-event.js");
const _temp102 = require("./directive/toggle-login-form-mode.js");
const _temp103 = require("./directive/validate-as.js");
const _temp104 = require("./directive/validate-cpr.js");
const _temp105 = require("./directive/wazamba-quick-deposit-behavior.js");
const _temp106 = require("./directive/E/body.js");
const _temp107 = require("./directive/E/form.js");
const _temp108 = require("./directive/E/head.js");
const _temp109 = require("./directive/E/linda-avatar-slick.js");
const _temp110 = require("./directive/E/linda-slick.js");

const _temp111 = require("./directive/crab/_bonus-crab-init.js");
const _temp112 = require("./directive/crab/claw-game-field.js");
const _temp113 = require("./directive/crab/crab-popup.js");
const _temp114 = require("./directive/crab/crab-sport-widget.js");

const _temp115 = require("./directive/bankid/auth-bankid.js");
const _temp116 = require("./directive/bankid/popup-bankid.js");

const _temp117 = require("./directive/dvs/dvs-drag-and-drop.js");
const _temp118 = require("./directive/dvs/dvs-file-upload.js");
const _temp119 = require("./directive/dvs/dvs-notification-not-verified.js");
const _temp120 = require("./directive/dvs/dvs-notification-verified.js");
const _temp121 = require("./directive/dvs/linda-document-verification-onfido.js");

const _temp122 = require("./directive/funid/show-if-funid.js");

const _temp123 = require("./directive/gringos/g.js");

const _temp124 = require("./directive/holidays/_summer-holiday-scene.js");
const _temp125 = require("./directive/holidays/_world-cup-calendar.js");
const _temp126 = require("./directive/holidays/_world-cup-video.js");

const _temp127 = require("./directive/linda/_linda-3d-slider.js");
const _temp128 = require("./directive/linda/linda-achievement-tooltip.js");
const _temp129 = require("./directive/linda/linda-avatar-slider.js");
const _temp130 = require("./directive/linda/linda-change-bonus-status.js");
const _temp131 = require("./directive/linda/linda-clock.js");
const _temp132 = require("./directive/linda/linda-count-up.js");
const _temp133 = require("./directive/linda/linda-first-view-change.js");
const _temp134 = require("./directive/linda/linda-first-view.js");
const _temp135 = require("./directive/linda/linda-fullscreen.js");
const _temp136 = require("./directive/linda/linda-goto-lang.js");
const _temp137 = require("./directive/linda/linda-goto.js");
const _temp138 = require("./directive/linda/linda-is-favourite.js");
const _temp139 = require("./directive/linda/linda-modal-reject-on-click.js");
const _temp140 = require("./directive/linda/linda-modal-resolve-on-click.js");
const _temp141 = require("./directive/linda/linda-odometer.js");
const _temp142 = require("./directive/linda/linda-password-type-toggle.js");
const _temp143 = require("./directive/linda/linda-profile-refresh-on-click.js");
const _temp144 = require("./directive/linda/linda-promo-notification.js");
const _temp145 = require("./directive/linda/linda-promo-slider.js");
const _temp146 = require("./directive/linda/linda-pwa.js");
const _temp147 = require("./directive/linda/linda-render-template.js");
const _temp148 = require("./directive/linda/linda-save-block-ratio.js");
const _temp149 = require("./directive/linda/linda-seo.js");
const _temp150 = require("./directive/linda/linda-splash.js");
const _temp151 = require("./directive/linda/linda-sport-field.js");
const _temp152 = require("./directive/linda/linda-sport-race.js");
const _temp153 = require("./directive/linda/linda-sport-widget-v3.js");
const _temp154 = require("./directive/linda/linda-sport-widget.js");
const _temp155 = require("./directive/linda/linda-swiper.js");
const _temp156 = require("./directive/linda/linda-tglab-sport-field.js");
const _temp157 = require("./directive/linda/linda-timer.js");
const _temp158 = require("./directive/linda/linda-toggle-favourite.js");
const _temp159 = require("./directive/linda/linda-translate.js");
const _temp160 = require("./directive/linda/linda-ui-sref-include.js");
const _temp161 = require("./directive/linda/linda-validate-birthday-format.js");
const _temp162 = require("./directive/linda/linda-validate-birthday.js");
const _temp163 = require("./directive/linda/linda-vision.js");
const _temp164 = require("./directive/linda/linda-zendesk.js");
const _temp165 = require("./directive/linda/balance-flow/balance-flow-datepicker.js");
const _temp166 = require("./directive/linda/balance-flow/balance-history-date-formatter.js");
const _temp167 = require("./directive/linda/balance-flow/linda-balance-flow-period-filter-datepicker.js");
const _temp168 = require("./directive/linda/balance-flow/linda-balance-flow-period-filter-jquery.js");
const _temp169 = require("./directive/linda/balance-flow/linda-balance-flow-period-filter-native.js");
const _temp170 = require("./directive/linda/balance-flow/linda-balance-flow-status-filter.js");
const _temp171 = require("./directive/linda/balance-flow/linda-balance-flow-type-filter.js");

const _temp172 = require("./directive/linda/chat/linda-chatra.js");
const _temp173 = require("./directive/linda/chat/linda-freshchat.js");
const _temp174 = require("./directive/linda/chat/linda-live-agent.js");
const _temp175 = require("./directive/linda/chat/linda-livechatinc.js");

const _temp176 = require("./directive/linda/trustly/linda-trustly-b-frame.js");
const _temp177 = require("./directive/linda/trustly/linda-trustly-frame.js");
const _temp178 = require("./directive/linda/trustly/paynplay-fast-deposit-button.js");
const _temp179 = require("./directive/linda/trustly/paynplay-resume-play-button.js");
const _temp180 = require("./directive/linda/trustly/remove-element-if-not-paynplay.js");
const _temp181 = require("./directive/linda/trustly/remove-element-if-paynplay.js");

const _temp182 = require("./directive/linda/zignsec/linda-zignsec-frame.js");
const _temp183 = require("./directive/linda/zignsec/zignsec-fast-deposit-button.js");
const _temp184 = require("./directive/linda/zignsec/zignsec-resume-play-button.js");

const _temp185 = require("./directive/linda/zimpler/linda-zimpler-frame.js");
const _temp186 = require("./directive/linda/zimpler/zimpler-fast-deposit-button.js");
const _temp187 = require("./directive/linda/zimpler/zimpler-resume-play-button.js");


const _temp188 = require("./directive/mga/mga-unverified-email-widget.js");

const _temp189 = require("./directive/mobile/body-scroll-lock.js");
const _temp190 = require("./directive/mobile/call-number.js");
const _temp191 = require("./directive/mobile/hide-after-scroll.js");
const _temp192 = require("./directive/mobile/keep-scroll-position.js");
const _temp193 = require("./directive/mobile/linda-modal-reject-on-tap.js");
const _temp194 = require("./directive/mobile/linda-modal-resolve-on-tap.js");
const _temp195 = require("./directive/mobile/m-datepicker.js");
const _temp196 = require("./directive/mobile/ng-dbtap.js");
const _temp197 = require("./directive/mobile/ng-tap.js");
const _temp198 = require("./directive/mobile/popup-close-tap.js");
const _temp199 = require("./directive/mobile/popup-open-tap.js");
const _temp200 = require("./directive/mobile/scroll-box.js");
const _temp201 = require("./directive/mobile/scroll-into-view-on-event.js");
const _temp202 = require("./directive/mobile/scroll-to-top-on-tap.js");
const _temp203 = require("./directive/mobile/smooth-scroll-tap.js");
const _temp204 = require("./directive/mobile/swipe-sidebar.js");
const _temp205 = require("./directive/mobile/swipe-to-close-notification.js");

const _temp206 = require("./directive/registration/registration-additional-fields.js");
const _temp207 = require("./directive/registration/registration-avatars-slider.js");
const _temp208 = require("./directive/registration/registration-promotions-slider.js");

const _temp209 = require("./directive/mobile-app/_all-href-target-system.js");
const _temp210 = require("./directive/mobile-app/_close-menu-swipe.js");
const _temp211 = require("./directive/mobile-app/_deferred-render.js");
const _temp212 = require("./directive/mobile-app/_game-item.js");
const _temp213 = require("./directive/mobile-app/_scroll-to.js");
const _temp214 = require("./directive/mobile-app/_virtual-scroll.js");
const _temp215 = require("./directive/mobile-app/call-number.js");
const _temp216 = require("./directive/mobile-app/deeplinking.js");
const _temp217 = require("./directive/mobile-app/demo-open.js");
const _temp218 = require("./directive/mobile-app/firebase-notifications.js");
const _temp219 = require("./directive/mobile-app/hide-after-scroll.js");
const _temp220 = require("./directive/mobile-app/keep-scroll-position.js");
const _temp221 = require("./directive/mobile-app/linda-modal-reject-on-touch.js");
const _temp222 = require("./directive/mobile-app/linda-modal-resolve-on-touch.js");
const _temp223 = require("./directive/mobile-app/m-datepicker.js");
const _temp224 = require("./directive/mobile-app/m-href.js");
const _temp225 = require("./directive/mobile-app/ng-tap-back.js");
const _temp226 = require("./directive/mobile-app/offline.js");
const _temp227 = require("./directive/mobile-app/open-chat-mobile.js");
const _temp228 = require("./directive/mobile-app/scroll-element-to-center.js");
const _temp229 = require("./directive/mobile-app/splashscreen.js");
const _temp230 = require("./directive/mobile-app/statusbar.js");
const _temp231 = require("./directive/mobile-app/swipe-to-close-notification.js");

const _temp232 = require("./directive/test/rabbit.js");

const _temp233 = require("./directive/slider/slider-promo.js");
const _temp234 = require("./directive/slider/slider-splide.js");
const _temp235 = require("./directive/slider/slider-vip.js");

const _temp236 = require("./directive/wall-of-wins/wall-of-wins-random.js");
const _temp237 = require("./directive/wall-of-wins/wall-of-wins.js");

module.exports = {
  "_alf-payment-slider-widget": _temp0,
  "_slider-range": _temp1,
  "_update-class-on-scroll-to": _temp2,
  "abandoned-registration": _temp3,
  "add-class-on-event": _temp4,
  "add-class-on-login": _temp5,
  "air-datepicker": _temp6,
  "animate-title-leave": _temp7,
  "bind-analytics-click": _temp8,
  "bind-analytics": _temp9,
  "bind-html-compile": _temp10,
  "cashbox-state": _temp11,
  "chat-open": _temp12,
  "check-last-deposit": _temp13,
  "check-pending-withdrawals": _temp14,
  "closed-registration-tracker": _temp15,
  "confetti": _temp16,
  "cookie-settings": _temp17,
  "copy-to-clipboard": _temp18,
  "datepicker-birthday": _temp19,
  "datepicker": _temp20,
  "embed-src": _temp21,
  "get-categories-to-scope": _temp22,
  "get-collection-gamehall-to-scope": _temp23,
  "get-collections-to-scope": _temp24,
  "get-config-to-scope": _temp25,
  "get-country-layout-to-scope": _temp26,
  "get-current-time-to-scope": _temp27,
  "get-footer-data-to-scope": _temp28,
  "get-modal-content-to-scope": _temp29,
  "get-popup-content-to-scope": _temp30,
  "get-private-categories": _temp31,
  "get-promo-links-to-scope": _temp32,
  "get-promos-to-scope": _temp33,
  "get-providers-to-scope": _temp34,
  "get-sport-teams-to-scope": _temp35,
  "get-state-params-to-scope": _temp36,
  "get-system-info-to-scope": _temp37,
  "get-timestamp-to-scope": _temp38,
  "get-user-to-scope": _temp39,
  "get-vips-to-scope": _temp40,
  "go-back": _temp41,
  "handle-game-page-shop-tab": _temp42,
  "hide-after-ny": _temp43,
  "hide-by-country-code": _temp44,
  "hide-element-if": _temp45,
  "hide-for-sw": _temp46,
  "history-go-back": _temp47,
  "holiday-link": _temp48,
  "horizontal-scroll-element": _temp49,
  "href-if-auth": _temp50,
  "infinite-scroll": _temp51,
  "input-auto-width": _temp52,
  "input-filter-creditcard": _temp53,
  "install-app-box": _temp54,
  "lazy-bg": _temp55,
  "license-icon": _temp56,
  "linda-scroll-top": _temp57,
  "logout": _temp58,
  "lottie-animation": _temp59,
  "lucky-winner-to-scope": _temp60,
  "mask-as": _temp61,
  "mask-phone-v2": _temp62,
  "mask-phone": _temp63,
  "mask-pt-postcode": _temp64,
  "mask-zipcode": _temp65,
  "native-datepicker": _temp66,
  "on-success": _temp67,
  "parallax": _temp68,
  "password-validate": _temp69,
  "password-verify": _temp70,
  "play-track-on-click": _temp71,
  "popup-close": _temp72,
  "popup-open": _temp73,
  "promo-button": _temp74,
  "regily-avatar-to-reward": _temp75,
  "remove-class-on-clickoutside": _temp76,
  "remove-class-on-event": _temp77,
  "remove-if-pwa": _temp78,
  "scroll-element-to-center-on-state-change": _temp79,
  "scroll-left-after-repeat": _temp80,
  "scroll-to-current-date": _temp81,
  "scroll-to-top-on-click": _temp82,
  "scroll-to-top": _temp83,
  "scroll-when-hold": _temp84,
  "select-template-given-expression": _temp85,
  "select-template-given-user-status": _temp86,
  "seo-text": _temp87,
  "set-popup-content-to": _temp88,
  "show-by-counrty-code": _temp89,
  "show-element-if": _temp90,
  "show-element-on-success": _temp91,
  "show-game-link-by-location": _temp92,
  "spine-player": _temp93,
  "swiper-slider": _temp94,
  "terms-modal-open": _temp95,
  "time-passed": _temp96,
  "title-switcher": _temp97,
  "toggle-class-at-elemetns": _temp98,
  "toggle-class-on-event": _temp99,
  "toggle-class-on-scroll": _temp100,
  "toggle-group-class-on-event": _temp101,
  "toggle-login-form-mode": _temp102,
  "validate-as": _temp103,
  "validate-cpr": _temp104,
  "wazamba-quick-deposit-behavior": _temp105,
  "E":   {
    "body": _temp106,
    "form": _temp107,
    "head": _temp108,
    "linda-avatar-slick": _temp109,
    "linda-slick": _temp110
  },
  "crab":   {
    "_bonus-crab-init": _temp111,
    "claw-game-field": _temp112,
    "crab-popup": _temp113,
    "crab-sport-widget": _temp114
  },
  "bankid":   {
    "auth-bankid": _temp115,
    "popup-bankid": _temp116
  },
  "dvs":   {
    "dvs-drag-and-drop": _temp117,
    "dvs-file-upload": _temp118,
    "dvs-notification-not-verified": _temp119,
    "dvs-notification-verified": _temp120,
    "linda-document-verification-onfido": _temp121
  },
  "funid":   {
    "show-if-funid": _temp122
  },
  "gringos":   {
    "g": _temp123
  },
  "holidays":   {
    "_summer-holiday-scene": _temp124,
    "_world-cup-calendar": _temp125,
    "_world-cup-video": _temp126
  },
  "linda":   {
    "_linda-3d-slider": _temp127,
    "linda-achievement-tooltip": _temp128,
    "linda-avatar-slider": _temp129,
    "linda-change-bonus-status": _temp130,
    "linda-clock": _temp131,
    "linda-count-up": _temp132,
    "linda-first-view-change": _temp133,
    "linda-first-view": _temp134,
    "linda-fullscreen": _temp135,
    "linda-goto-lang": _temp136,
    "linda-goto": _temp137,
    "linda-is-favourite": _temp138,
    "linda-modal-reject-on-click": _temp139,
    "linda-modal-resolve-on-click": _temp140,
    "linda-odometer": _temp141,
    "linda-password-type-toggle": _temp142,
    "linda-profile-refresh-on-click": _temp143,
    "linda-promo-notification": _temp144,
    "linda-promo-slider": _temp145,
    "linda-pwa": _temp146,
    "linda-render-template": _temp147,
    "linda-save-block-ratio": _temp148,
    "linda-seo": _temp149,
    "linda-splash": _temp150,
    "linda-sport-field": _temp151,
    "linda-sport-race": _temp152,
    "linda-sport-widget-v3": _temp153,
    "linda-sport-widget": _temp154,
    "linda-swiper": _temp155,
    "linda-tglab-sport-field": _temp156,
    "linda-timer": _temp157,
    "linda-toggle-favourite": _temp158,
    "linda-translate": _temp159,
    "linda-ui-sref-include": _temp160,
    "linda-validate-birthday-format": _temp161,
    "linda-validate-birthday": _temp162,
    "linda-vision": _temp163,
    "linda-zendesk": _temp164,
    "balance-flow":     {
      "balance-flow-datepicker": _temp165,
      "balance-history-date-formatter": _temp166,
      "linda-balance-flow-period-filter-datepicker": _temp167,
      "linda-balance-flow-period-filter-jquery": _temp168,
      "linda-balance-flow-period-filter-native": _temp169,
      "linda-balance-flow-status-filter": _temp170,
      "linda-balance-flow-type-filter": _temp171
    },
    "chat":     {
      "linda-chatra": _temp172,
      "linda-freshchat": _temp173,
      "linda-live-agent": _temp174,
      "linda-livechatinc": _temp175
    },
    "trustly":     {
      "linda-trustly-b-frame": _temp176,
      "linda-trustly-frame": _temp177,
      "paynplay-fast-deposit-button": _temp178,
      "paynplay-resume-play-button": _temp179,
      "remove-element-if-not-paynplay": _temp180,
      "remove-element-if-paynplay": _temp181
    },
    "zignsec":     {
      "linda-zignsec-frame": _temp182,
      "zignsec-fast-deposit-button": _temp183,
      "zignsec-resume-play-button": _temp184
    },
    "zimpler":     {
      "linda-zimpler-frame": _temp185,
      "zimpler-fast-deposit-button": _temp186,
      "zimpler-resume-play-button": _temp187
    }
  },
  "mga":   {
    "mga-unverified-email-widget": _temp188
  },
  "mobile":   {
    "body-scroll-lock": _temp189,
    "call-number": _temp190,
    "hide-after-scroll": _temp191,
    "keep-scroll-position": _temp192,
    "linda-modal-reject-on-tap": _temp193,
    "linda-modal-resolve-on-tap": _temp194,
    "m-datepicker": _temp195,
    "ng-dbtap": _temp196,
    "ng-tap": _temp197,
    "popup-close-tap": _temp198,
    "popup-open-tap": _temp199,
    "scroll-box": _temp200,
    "scroll-into-view-on-event": _temp201,
    "scroll-to-top-on-tap": _temp202,
    "smooth-scroll-tap": _temp203,
    "swipe-sidebar": _temp204,
    "swipe-to-close-notification": _temp205
  },
  "registration":   {
    "registration-additional-fields": _temp206,
    "registration-avatars-slider": _temp207,
    "registration-promotions-slider": _temp208
  },
  "mobile-app":   {
    "_all-href-target-system": _temp209,
    "_close-menu-swipe": _temp210,
    "_deferred-render": _temp211,
    "_game-item": _temp212,
    "_scroll-to": _temp213,
    "_virtual-scroll": _temp214,
    "call-number": _temp215,
    "deeplinking": _temp216,
    "demo-open": _temp217,
    "firebase-notifications": _temp218,
    "hide-after-scroll": _temp219,
    "keep-scroll-position": _temp220,
    "linda-modal-reject-on-touch": _temp221,
    "linda-modal-resolve-on-touch": _temp222,
    "m-datepicker": _temp223,
    "m-href": _temp224,
    "ng-tap-back": _temp225,
    "offline": _temp226,
    "open-chat-mobile": _temp227,
    "scroll-element-to-center": _temp228,
    "splashscreen": _temp229,
    "statusbar": _temp230,
    "swipe-to-close-notification": _temp231
  },
  "test":   {
    "rabbit": _temp232
  },
  "slider":   {
    "slider-promo": _temp233,
    "slider-splide": _temp234,
    "slider-vip": _temp235
  },
  "wall-of-wins":   {
    "wall-of-wins-random": _temp236,
    "wall-of-wins": _temp237
  }
}