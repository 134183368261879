import '@b2c/core/app/service/domain/user.js'
(function(){

  'use strict';

  const directive = { name: 'disableWithdrawalList' };

  controller.$inject = ['user'];

  function controller(_user){

    function link( scope ){

      const withdrawalList = document.querySelector('#payments-withdrawal-list');

      if (withdrawalList) {
        const limitText = withdrawalList.querySelector('#request-limit-text');

        scope.$watch('collection', function() {
          if (scope.collection.length > 2) {
            withdrawalList.classList.add('disabled');
            limitText.classList.add('visible')
          } else if (_user.profile.balance < 10) {
            withdrawalList.classList.add('disabled');
            limitText.classList.add('visible')
          } else {
            withdrawalList.classList.remove('disabled');
            limitText.classList.remove('visible')
          }
        });
      }
    }

    return {
      restrict: 'A',
      link: link
    };
  }

  app.directive( directive.name, controller );
})();

